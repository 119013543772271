<!-- follow-contact -->
<template>
  <el-drawer
    class="follow-contact"
    :visible.sync="open"
    :title="title"
    size="25%"
    :modal="false"
    @open="handleDrawerOpen"
  >
    <template v-slot:title>
      <custom-title :title="title" />
    </template>

    <div
      v-if="followStatus === followStatusEnum.following"
      class="follow-contact-operate"
    >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handleOperateAddClick"
        >新增
      </el-button>
    </div>

    <el-scrollbar class="follow-contact-scrollbar" v-loading="loading">
      <!-- 联系记录空占位符 -->
      <empty-placeholder
        v-if="!followContacts || !followContacts.length"
        placeholder="暂无联系记录"
      />

      <el-timeline v-else class="follow-contact-timeline">
        <el-timeline-item
          v-for="contact in followContacts"
          placement="top"
          size="large"
          :type="
            contact.result === followContactResultEnum.ok
              ? 'success'
              : 'warning'
          "
          :icon="
            contact.result === followContactResultEnum.ok
              ? 'el-icon-success'
              : 'el-icon-info'
          "
          :timestamp="contact.createTime"
          :key="contact._id"
        >
          <!-- 时间线项操作容器 -->
          <div
            v-if="
              followStatus === followStatusEnum.following &&
              (currentUserHasFullPermissions ||
                contact.userId === currentUserId)
            "
            class="timeline-item-operate"
          >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="handleTimelineItemEditClick(contact)"
            />

            <el-button
              class="operate-remove"
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="handleTimelineItemRemoveClick(contact)"
            />
          </div>

          <!-- 时间线项内容容器 -->
          <el-card class="timeline-item-card">
            <div class="item-title" v-html="genTimelineTitle(contact)" />
            <div class="item-content">{{ contact.content }}</div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>

    <!-- 新增/编辑联系记录对话框 -->
    <follow-contact-edit
      v-model="showEditDialog"
      @on-ok="handleFollowContactEditDialogOkClick"
    />

    <!-- 删除联系记录对话框 -->
    <follow-contact-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-ok="handleFollowContactRemoveDialogOkClick"
    />
  </el-drawer>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import CustomTitle from '../../../../components/custom-title';
  import EmptyPlaceholder from '../../../../components/empty-placeholder';
  import followStatus from '../../../../enum/follow-status';
  import followContactResult from '../../../../enum/follow-contact-result';
  import { removeFollowContact } from '../../../../api/anchor/follow';

  export default {
    name: 'follow-contact',
    components: {
      EmptyPlaceholder,
      CustomTitle,
      FollowContactEdit: () =>
        import(/* webpackChunkName: 'follow-contact-edit' */ './contact-edit'),
      FollowContactRemove: () =>
        import(
          /* webpackChunkName: 'follow-contact-remove' */ '../../../../components/confirm'
        ),
    },
    props: {
      // 是否开启抽屉
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        operateId: '',
        showEditDialog: false,
        showRemoveDialog: false,
        loading: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('anchor/follow', ['followContacts']),
      ...mapGetters(['currentUserId', 'currentUserHasFullPermissions']),
      ...mapGetters('anchor/follow', [
        'followAnchorName',
        'followStatus',
        'followUserId',
      ]),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 抽屉标题
      title() {
        return `${this.followAnchorName} - 联系记录`;
      },
      // 跟进状态枚举值
      followStatusEnum() {
        return followStatus.enum;
      },
      // 联系结果枚举值
      followContactResultEnum() {
        return followContactResult.enum;
      },
    },
    beforeDestroy() {
      this.mttFollowContactId();
    },
    methods: {
      ...mapMutations('anchor/follow', ['mttFollowContactId']),
      ...mapActions('anchor/follow', ['actFollowContacts', 'actFollows']),

      // 生成时间线 title
      genTimelineTitle(contact) {
        const {
          username = '',
          typeName = '',
          number = '',
          result,
          resultName = '',
        } = contact;
        const followContactResultOkValue = followContactResult.enum.ok;
        const followContactResultOkText =
          followContactResult.map[followContactResultOkValue];

        return `<span class="highlight">${username}</span>通过<span class="highlight">${typeName}(${number})</span>与主播进行联系，联系结果为:<span class="highlight">${
          result === followContactResultOkValue
            ? followContactResultOkText
            : resultName
        }</span>。具体联系内容如下:`;
      },
      // 获取联系记录列表
      async getFollowContacts() {
        this.loading = true;

        const success = await this.actFollowContacts();

        this.loading = false;

        return success;
      },
      // 删除联系记录
      async removeFollowContact() {
        const id = this.operateId;

        if (!id) return;

        this.removing = true;

        const res = await removeFollowContact(id);

        this.removing = false;

        return !!res;
      },
      // 抽屉打开
      handleDrawerOpen() {
        this.getFollowContacts();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 联系记录项编辑按钮单击
      handleTimelineItemEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 联系记录项删除按钮单击
      handleTimelineItemRemoveClick({ _id }) {
        this.operateId = _id;
        this.showRemoveDialog = true;
      },
      // 新增/编辑联系记录对话框确定按钮执行成功
      handleFollowContactEditDialogOkClick(contactResult) {
        this.getFollowContacts();

        // 在新增/编辑了联系记录后，跟进的状态可能变更为'跟进完成'，需要更新跟进列表
        if (contactResult !== followContactResult.enum.ok) return;

        this.actFollows();
      },
      // 删除联系记录对话框确定按钮单击
      async handleFollowContactRemoveDialogOkClick() {
        const success = await this.removeFollowContact();

        if (!success) return;

        this.showRemoveDialog = false;

        this.getFollowContacts();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttFollowContactId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .follow-contact {
    /deep/.el-drawer__header {
      margin-bottom: 15px;
    }

    /deep/.el-drawer__body {
      flex-direction: column;

      .follow-contact-operate {
        padding: 0 15px;
        margin-bottom: 20px;
      }

      .follow-contact-scrollbar {
        flex-grow: 1;

        .el-scrollbar__wrap {
          height: 100%;
          overflow: hidden auto;
        }
      }

      .follow-contact-timeline {
        padding: 0 15px;

        .el-timeline-item {
          .el-timeline-item__content {
            position: relative;
          }

          // 时间线项操作容器
          .timeline-item-operate {
            position: absolute;
            top: -28px;
            right: 0;
            z-index: 1000;
            display: flex;
            justify-content: flex-end;
            width: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            .el-button {
              min-width: auto;
              width: 22px;

              &.operate-remove {
                color: #f56c6c;
              }
            }
          }

          // 时间线项内容容器
          .timeline-item-card {
            line-height: 22px;
            font-size: 13px;
            color: #777;

            .item-title {
              > .highlight {
                padding: 0 5px;
                color: #409eff;

                &:nth-of-type(1) {
                  padding-left: 0;
                }
              }
            }
          }

          &:hover .timeline-item-operate {
            width: 60px;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
