var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{staticClass:"follow-contact",attrs:{"visible":_vm.open,"title":_vm.title,"size":"25%","modal":false},on:{"update:visible":function($event){_vm.open=$event},"open":_vm.handleDrawerOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('custom-title',{attrs:{"title":_vm.title}})]},proxy:true}])},[(_vm.followStatus === _vm.followStatusEnum.following)?_c('div',{staticClass:"follow-contact-operate"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","size":"mini"},on:{"click":_vm.handleOperateAddClick}},[_vm._v("新增 ")])],1):_vm._e(),_c('el-scrollbar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"follow-contact-scrollbar"},[(!_vm.followContacts || !_vm.followContacts.length)?_c('empty-placeholder',{attrs:{"placeholder":"暂无联系记录"}}):_c('el-timeline',{staticClass:"follow-contact-timeline"},_vm._l((_vm.followContacts),function(contact){return _c('el-timeline-item',{key:contact._id,attrs:{"placement":"top","size":"large","type":contact.result === _vm.followContactResultEnum.ok
            ? 'success'
            : 'warning',"icon":contact.result === _vm.followContactResultEnum.ok
            ? 'el-icon-success'
            : 'el-icon-info',"timestamp":contact.createTime}},[(
            _vm.followStatus === _vm.followStatusEnum.following &&
            (_vm.currentUserHasFullPermissions ||
              contact.userId === _vm.currentUserId)
          )?_c('div',{staticClass:"timeline-item-operate"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.handleTimelineItemEditClick(contact)}}}),_c('el-button',{staticClass:"operate-remove",attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.handleTimelineItemRemoveClick(contact)}}})],1):_vm._e(),_c('el-card',{staticClass:"timeline-item-card"},[_c('div',{staticClass:"item-title",domProps:{"innerHTML":_vm._s(_vm.genTimelineTitle(contact))}}),_c('div',{staticClass:"item-content"},[_vm._v(_vm._s(contact.content))])])],1)}),1)],1),_c('follow-contact-edit',{on:{"on-ok":_vm.handleFollowContactEditDialogOkClick},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}}),_c('follow-contact-remove',{attrs:{"ok-button-loading":_vm.removing},on:{"on-ok":_vm.handleFollowContactRemoveDialogOkClick},model:{value:(_vm.showRemoveDialog),callback:function ($$v) {_vm.showRemoveDialog=$$v},expression:"showRemoveDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }